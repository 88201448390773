<template>
  <div>
    <import-excel-to-json
      :key="importComponentKey"
      v-if="showImportComponent"
      :target-headers="columnDefsToImport"
      :entity="entity"
      :on-save-import="importTagsFromFile"
      @refresh="importComponentKey+=1"
      @imported="resetDataSourceAndSelection()"
      @close="showImportComponent=false"/>

    <div
      v-show="!showImportComponent"
      class="vx-card p-6">
      <base-ag-grid-header
        :rows-selected-count="rowsSelectedCountWithAllSelection"
        :filters-count="filtersAppliedCount"
        :filters-match.sync="filtersMatch"
        @reset-selection="setSelectedRowsNone()"
        @reset-filters="resetFilters()">
        <template v-slot:header-left>
          <items-per-page
            :count="count"
            :currentPage="currentPage"
            :paginationPageSize="paginationPageSize"
            @changed-page-size="paginationSetPageSize">
          </items-per-page>

          <list-action-drop-down
            v-if="isAnyRowsSelected"
            :options="defaultMultipleActionOptions"
            class="ml-3"
            @export="exportData()"
            @delete="confirmDeleteRecords()">
          </list-action-drop-down>
        </template>

        <template v-slot:header-right>
          <vs-button
            color="primary"
            type="border"
            :size="headerButtonsSizeByResolution"
            icon-pack="feather"
            icon="icon-plus"
            class="mr-3"
            @click="newItem">
            {{ $t("$General.AddNew") }}
          </vs-button>

          <vs-button
            color="primary"
            :size="headerButtonsSizeByResolution"
            icon-pack="feather"
            icon="icon-upload"
            class="mr-3"
            @click="showImportComponent=true">
            {{ $t("$General.Import") }}
          </vs-button>

          <vs-button
            radius
            color="primary"
            type="border"
            icon-pack="feather"
            icon="icon-refresh-cw"
            @click="resetDataSourceAndSelection()">
        </vs-button>
        </template>
      </base-ag-grid-header>

      <selection-alert-info
        v-model="selectionPageAlert"
        class="mb-5"
        :entity="entity"
        :count="count"
        :rows-selected-count="rowsSelectedCount"
        @select-all="setSelectedRowsAll()"></selection-alert-info>

      <export-alert-info
        class="mb-5"
        :entity="$tc(`$Entities.${entity}`, 2)"
        :exporting.sync="exportingList"
        :exported.sync="exportedList"
        :fileUrl="urlFileExported"
        :send-email="exportSendEmail"/>

      <ag-grid-vue
        :key="agGridKey"
        ref="agGridTable"
        :domLayout="domLayout"
        :style="gridStyle"
        :components="components"
        :frameworkComponents="frameworkComponents"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :column-types="columnTypes"
        :getRowNodeId="getRowNodeId"
        :autoParamsRefresh="true"
        rowSelection="multiple"
        :animateRows="true"
        :suppressRowClickSelection="true"
        rowModelType="infinite"
        :pagination="hasPagination"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="suppressPaginationPanel"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
        :cacheBlockSize="cacheBlockSize"
        :maxBlocksInCache="maxBlocksInCache"
        @selection-changed="onSelectionChanged"
        @grid-ready="onGridReady"
        @model-updated="onModelUpdate"
        @grid-size-changed="onGridSizeChanged"
        @first-data-rendered="onFirstDataRendered"
        @row-clicked="onRowClicked"
        @row-double-clicked="onRowDoubleClicked"
        @filter-changed="onFilterChanged"
        @pagination-changed="onPaginationChanged">
      </ag-grid-vue>

      <vs-popup
        :title="titleModal"
        :active.sync="activeModalCreateOrEdit">
        <transition name="zoom-fade">
          <tag-list-create-or-edit
            v-if="showCreateOrEditComponent"
            :entity="entity"
            :operation="operation"
            :model-payload="recordSelected"
            :actions="actionsOnCreateOrEdit"
            @action="onActionFromCreateOrEdit"
            @model-was-changed="(val) => this.createOrEditModelWasChanged=val"
            @saved="savedItemOnAgGridTable"
            @close="activeModalCreateOrEdit=false">
          </tag-list-create-or-edit>
        </transition>
      </vs-popup>

      <vs-popup
        :title="exportModalTitle"
        :active.sync="activeModalToExport">
        <transition name="zoom-fade">
          <export-json-to-excel
            v-if="showExportComponent"
            :columns="getColumnsToExport"
            :data="rowsSelected"
            @close="activeModalToExport=false"
            @export="onExport">
          </export-json-to-excel>
        </transition>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import { mapActions } from 'vuex';

// Mixins
import commonListCreateOrEditWithAgGrid from '@/views/modules/_mixins/commonListCreateOrEditWithAgGrid';

// Cell Renderer
import CellRendererActions from '@/views/modules/_components/cell-renderer/SingleCellRendererActions.vue';

// Custom Components
import BaseAgGridHeader from '@/views/modules/_components/BaseAgGridHeader.vue';
import ItemsPerPage from '@/views/modules/_components/ItemsPerPage.vue';
import ExportJsonToExcel from '@/views/modules/_components/ExportJsonToExcel.vue';
import ListActionDropDown from '@/views/modules/_components/ListActionDropDown.vue';
import TagListCreateOrEdit from '@/views/modules/tags/TagListCreateOrEdit.vue';
import AgGridSelectionHeader from '@/views/modules/_components/AgGridSelectionHeader';
import ExportAlertInfo from '@/views/modules/_components/ExportAlertInfo.vue';
import ImportExcelToJson from '@/views/modules/_components/import-exce-to-json/ImportExcelToJson.vue';
import SelectionAlertInfo from '@/views/modules/SelectionAlertInfo.vue';

/**
 * Component to list and manage audience tags
 *
 * @module views/modules/audience/tag/TagList
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} [entity='...'] - Name of the entity associated to list
 * @vue-data {boolean} cloneDisabled - indicate if clone operation if disabled
 * @vue-data {Function} exportCollectionFunction - function to export the collection
 * @vue-data {Function | null} deleteRecordFunction - function to delete one record
 * @vue-data {Function | null} deleteRecordsFunction - function to delete many records
 * @vue-data {Array.<Object>} columnDefs - Columns definition of ag grid to list
 * @vue-data {Object} components - Components to use to extend ag grid
 * @vue-data {Object} frameworkComponents - Framework components to extend ag grid
 * @vue-event {void} fetchAgGridData - fetch data to ag grid table
 */
export default {
  name: 'TagList',
  components: {
    BaseAgGridHeader,
    TagListCreateOrEdit,
    ItemsPerPage,
    ListActionDropDown,
    ExportJsonToExcel,
    ExportAlertInfo,
    ImportExcelToJson,
    SelectionAlertInfo,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererActions,
  },
  mixins: [commonListCreateOrEditWithAgGrid],
  data() {
    return {
      entity: this.$enums.Entity.TAG,
      cloneDisabled: true,
      exportCollectionFunction: this.exportTags,
      deleteRecordFunction: this.removeTag,
      deleteRecordsFunction: this.removeTags,
      modalsDelay: 0,
      columnDefs: [
        {
          colId: 'name',
          headerName: this.$t('$General.Name'),
          field: 'name',
          filter: true,
          minWidth: 500,
          maxWidth: 2866,
          checkboxSelection: true,
          headerComponentParams: { checkboxSelection: true },
          requireOnImport: true,
        },
      ],
      components: {
        CellRendererActions,
      },
      frameworkComponents: {
        agColumnHeader: AgGridSelectionHeader,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchAllTags: 'tag/fetchAllTags',
      importTagsFromFile: 'tag/importFromFile',
      exportTags: 'tag/exportFile',
      removeTag: 'tag/removeTag',
      removeTags: 'tag/removeTags',
    }),
    async fetchAgGridData(params) {
      return this.fetchAllTags(params);
    },
  },
};

</script>
