import BaseConstructor from '@/views/modules/_common/base.constructor';
import enums from '@/enums';

/**
 * Create a new tag
 * @class TagConstructor
 */
export default class TagConstructor extends BaseConstructor {
  name = '';

  constructor(operation = enums.Operation.CREATE, payload = null) {
    super(operation, payload);

    this.name = operation === enums.Operation.CREATE || !payload ? '' : payload.name || '';
  }

  toCreatePayload() {
    return {
      name: this.name,
    };
  }

  toEditPayload() {
    return {
      id: this.payload.id,
      ...this.toCreatePayload(),
    };
  }
}
