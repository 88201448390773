<template>
  <form @submit.prevent>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          v-model="model.name"
          class="w-full"
          :class="{required: !isView}"
          :disabled="isView"
          :name="$t('$General.Name')"
          :label="$t('$General.Name')"
          type="text"
          v-validate="'required|max:100'"
          data-vv-validate-on="blur|input"
          :danger="errors.has($t('$General.Name'))"
          :danger-text="errors.first($t('$General.Name'))"
          val-icon-danger="clear"/>
      </div>
    </div>

    <base-form-footer-action-buttons
      :hide-save="isView"
      :actions="mappedActions"
      @action="onAction"
      @save="save(model.toSavePayload())"
      @cancel="$emit('close')">
      <template
        v-if="isView"
        v-slot:cancel>
        {{ $t("$General.Close") }}
      </template>
    </base-form-footer-action-buttons>
  </form>
</template>

<script>
import { mapActions } from 'vuex';

// Constructor
import TagConstructor from '@/views/modules/tags/tag.constructor';

// Mixins
import singleCreateOrEdit from '@/views/modules/_mixins/singleCreateOrEdit';

// Custom Components
import BaseFormFooterActionButtons from '@/views/modules/_components/BaseFormFooterActionButtons.vue';

/**
 * Component to create or edit audience tags
 *
 * @module views/modules/audience/tag/TagListCreateOrEdit
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object} [model={...}] - attribute model to save
 * @vue-data {Function | null} [addItemFunction=null] - function to add one tag
 * @vue-data {Function | null} [editItemFunction=null] - function to edit one tag
 * @vue-computed  {boolean} verifyProperties - verify is form to save is valid
 */
export default {
  name: 'TagListCreateOrEdit',
  components: {
    BaseFormFooterActionButtons,
  },
  mixins: [singleCreateOrEdit],
  data() {
    return {
      model: new TagConstructor(this.operation, this.modelPayload),
      addItemFunction: this.addTag,
      editItemFunction: this.editTag,
    };
  },
  computed: {
    verifyProperties() {
      return this.model.name !== '';
    },
  },
  methods: {
    ...mapActions({
      addTag: 'tag/addTag',
      editTag: 'tag/editTag',
    }),
  },
};
</script>
